import React, { InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import { FieldError } from 'react-hook-form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError
}

const FullnameInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, error, ...rest }, ref) => {
    return (
      <input
        ref={ref}
        type="text"
        autoComplete="off"
        autoCorrect="off"
        placeholder="Your full name"
        className={clsx('form-input rounded-full w-full px-4', className, {
          error,
        })}
        {...rest}
      />
    )
  }
)

export default FullnameInput
