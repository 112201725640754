import { useReducer, useState } from 'react'
import { useTimer } from 'react-timer-hook'

const TOTAL_NOTIFICATION_SCREEN_TIME = 6500

export interface WebAppNotification {
  notificationState: NotificationState
  triggerNotification: (startTimer?: boolean) => Promise<void>
  dismissNotification: () => void
  pauseNotificationTimer: () => void
  resumeNotificationTimer: () => void
  activateNotification: () => void
}

export interface NotificationState {
  display: boolean
  activated: boolean
  loading: boolean
  paused: boolean
  timerStarted: boolean
  timerCleared: boolean
  hovered: boolean
}

export const defaultNotificationState: NotificationState = {
  display: false,
  activated: false,
  loading: false,
  paused: false,
  timerStarted: false,
  timerCleared: false,
  hovered: false,
}

type NotificationAction =
  | 'trigger'
  | 'activated'
  | 'dismiss'
  | 'processed'
  | 'pause'
  | 'resume'
  | 'startTimer'
  | 'clearTimer'

const useWebAppNotification = (): WebAppNotification => {
  const [onNotificationTimeout, setOnNotificationTimeout] =
    useState<() => void>()
  const { isRunning, pause, resume, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => {
      if (notificationState.timerStarted) {
        if (typeof onNotificationTimeout === 'function') {
          onNotificationTimeout()
        }
        dispatch('dismiss')
      }
    },
  })

  const notificationReducer = (
    state: NotificationState,
    action: NotificationAction
  ) => {
    switch (action) {
      case 'trigger': {
        return {
          ...defaultNotificationState,
          display: true,
          loading: true,
        }
      }
      case 'activated': {
        return {
          ...state,
          activated: true,
        }
      }
      case 'processed':
        return {
          ...state,
          loading: false,
        }
      case 'pause': {
        if (isRunning) {
          pause()
          return {
            ...state,
            hovered: true,
          }
        }
        return {
          ...state,
        }
      }
      case 'startTimer': {
        const timeObject = new Date(
          new Date().getTime() + TOTAL_NOTIFICATION_SCREEN_TIME
        )
        restart(timeObject)

        if (state.hovered) {
          pause()
        }
        return {
          ...state,
          timerStarted: true,
        }
      }
      case 'resume': {
        //Do not resume if note input field is active
        if (!isRunning && !state.timerCleared && !state.activated) {
          resume()
          return {
            ...state,
            timerStarted: true,
            hovered: false,
          }
        }
        return {
          ...state,
        }
      }
      case 'dismiss': {
        // Clear any onNotificationTimeout callbacks
        setOnNotificationTimeout(undefined)

        // Reset to original notification state, with `loading` false
        return {
          ...state,
          ...defaultNotificationState,
          loading: false,
        }
      }
    }
  }

  const [notificationState, dispatch] = useReducer(
    notificationReducer,
    defaultNotificationState
  )

  const triggerNotification = async (startTimer = true) => {
    dispatch('trigger')

    if (startTimer) {
      dispatch('startTimer')
    }

    // artificial wait here so it looks like
    // "something is happening"
    await new Promise((r) => setTimeout(r, 500))

    dispatch('processed')
  }

  const dismissNotification = () => dispatch('dismiss')
  const pauseNotificationTimer = () => dispatch('pause')
  const resumeNotificationTimer = () => dispatch('resume')
  const activateNotification = () => dispatch('activated')

  return {
    triggerNotification,
    dismissNotification,
    notificationState,
    pauseNotificationTimer,
    resumeNotificationTimer,
    activateNotification,
  }
}

export default useWebAppNotification
