import React, { useEffect, useState } from 'react'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import ProviderLogin from '@carrotcart/app/components/ProviderLogin'
import { SignupCampaign } from '@carrotcart/common/lib/constants'
import MiniOnboard from '@carrotcart/app/components/MiniOnboard'
import ModalDialog from '@carrotcart/client-common/components/ModalDialog'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import SignUpModal from '@carrotcart/app/components/SignUpModal'
import { itemImageUrl } from '@carrotcart/client-common/lib/helpers'
import { AuthCallback } from '@carrotcart/app/types'
import SignupWithCallbackContent from './SignupWithCallbackContent'
import SignupWithPremiumContent from './SignupWithPremiumContent'
import GeneralSignupWallContent from './GeneralSignupWallContent'
import GiftingSignUpWallContent from './GiftingSignUpWallContent'

interface ProviderProps {
  children?: React.ReactNode
  signupCampaign?: SignupCampaign
  callback?: AuthCallback
}

const providerLogin = (
  {
    signupCampaign = SignupCampaign.SignupWall,
    callback,
    children,
  }: ProviderProps = {
    signupCampaign: SignupCampaign.SignupWall,
  }
) => {
  return (
    <ProviderLogin
      signupEventProperties={{ utm_campaign: signupCampaign }}
      callback={callback}
      hideAccountSwitcher
      hideIntlCTA
      signupFlow
    >
      {children}
    </ProviderLogin>
  )
}

const SignUpWallWithCallback: React.FC = ({ children }) => {
  const { currentUser } = useWebAppContext()
  const { signupWallParams, closeSignupWall, showMiniOnboarding } =
    useSignupWallContext()
  const [callbackCalled, setCallbackCalled] = useState(false)

  const {
    callback,
    type,
    cartItem,
    customTitle,
    customCopy,
    avatarSrc,
    collection,
    dismissOnSignup,
    disableClose,
  } = signupWallParams

  useEffect(() => {
    if (callbackCalled) return
    if (!currentUser?.id) return
    if (showMiniOnboarding) return
    if (!callback) return

    if (dismissOnSignup) {
      closeSignupWall()
    }

    callback(!!currentUser?.id)
    setCallbackCalled(true)
  }, [
    currentUser?.id,
    callback,
    closeSignupWall,
    showMiniOnboarding,
    callbackCalled,
    dismissOnSignup,
  ])

  let content: React.ReactNode
  let size: ReturnType<typeof ModalDialog>['props']['size'] = 'x-large'

  switch (type) {
    case 'with_custom_callback': {
      const itemImage = itemImageUrl(
        cartItem?.item,
        cartItem?.item_image_override
      )

      size = itemImage ? 'xxx-large' : size
      content = (
        <SignupWithCallbackContent
          providerLogin={providerLogin({
            children,
          })}
          miniOnboard={showMiniOnboarding && <MiniOnboard />}
          customTitle={customTitle}
          customCopy={customCopy}
          avatarSrc={avatarSrc}
          itemImage={itemImage}
        />
      )
      break
    }
    case 'premium': {
      content = (
        <SignupWithPremiumContent
          providerLogin={providerLogin({
            children,
          })}
          miniOnboard={showMiniOnboarding && <MiniOnboard />}
          collection={collection}
        />
      )
      break
    }
    case 'gifting': {
      const gifteeName = cartItem?.cart?.user?.name || ''
      const itemImage = itemImageUrl(
        cartItem?.item,
        cartItem?.item_image_override
      )

      size = 'xxx-large'
      content = (
        <GiftingSignUpWallContent
          providerLogin={providerLogin({
            children,
            signupCampaign: SignupCampaign.GiftSignupWall,
          })}
          miniOnboard={showMiniOnboarding && <MiniOnboard />}
          gifteeName={gifteeName}
          itemImage={itemImage}
        />
      )
      break
    }
    default: {
      content = (
        <GeneralSignupWallContent
          providerLogin={providerLogin({ children })}
          miniOnboard={showMiniOnboarding && <MiniOnboard />}
        />
      )
    }
  }

  return (
    <SignUpModal
      size={size}
      dismissOnSignup={dismissOnSignup}
      disableClose={disableClose}
      callback={callback}
    >
      {content}
    </SignUpModal>
  )
}

export default SignUpWallWithCallback
