import clsx from 'clsx'
import ProgressLinear from '@carrotcart/client-common/components/ProgressLinear'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import type { SignupChildProps } from '@carrotcart/app/components/SignUpWallWithCallback/types'
import PictureAvatar from '@carrotcart/app/components/PictureAvatar'

interface Props extends SignupChildProps {
  customTitle?: string
  customCopy?: string
  avatarSrc?: string
  itemImage?: string
}

const SignupWithCallbackContent: React.FC<Props> = ({
  miniOnboard,
  providerLogin,
  customTitle,
  customCopy,
  avatarSrc,
  itemImage,
}) => {
  const { authUser, isAnAnonymousAuthUser, loadingApp } = useWebAppContext()

  return (
    <div className="flex flex-col md:flex-row lg:max-w-4xl xl:max-w-6xl mx-auto bg-white rounded-lg overflow-hidden relative max-h-[90vh]">
      <div className={clsx('absolute z-10 top-0 inset-x-0')}>
        <ProgressLinear visible={loadingApp} />
      </div>
      <div className="w-full min-h-[420px] order-2 md:order-1 flex flex-col justify-center items-center p-10 xl:p-16">
        <div className="flex-1 flex flex-col justify-center items-center space-y-8">
          {miniOnboard}
          {(!authUser?.uid || isAnAnonymousAuthUser) && (
            <div className="flex flex-col items-center text-center space-y-2">
              {avatarSrc && (
                <PictureAvatar picture={avatarSrc} rounded size="onboarding" />
              )}
              <div className="mx-auto font-display text-center text-3xl md:text-3xl xl:text-4xl">
                {customTitle || 'Sign up to continue'}
              </div>
              <div className="text-gray mx-auto text-lg px-8 max-w-[450px]">
                {customCopy ||
                  'Discover the best stuff on the internet from the world’s tastemakers'}
              </div>
            </div>
          )}
          {providerLogin}
        </div>
      </div>
      {itemImage && (
        <div className="relative order-1 md:order-2 pointer-events-none overflow-hidden">
          <div className="bg-[rgba(0,0,0,0.15)] absolute bottom-0 left-0 right-0 top-0 z-1 bg-blend-overlay opacity-30" />
          <img
            src={itemImage}
            className="block object-cover aspect-[3/2] h-full md:h-full w-full max-w-full"
          />
        </div>
      )}
    </div>
  )
}

export default SignupWithCallbackContent
