import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence, Variants } from 'framer-motion'
import {
  default as PictureAvatar,
  PictureAvatarProps,
} from '@carrotcart/app/components/PictureAvatar'
import type { Maybe } from '@carrotcart/data/generated/types'

interface PictureAvatarGroupingProps extends PictureAvatarProps {
  pictures: (Maybe<string> | undefined)[]
  picturesCount?: number
  limit?: number
  showPlaceholders?: boolean
  countBgColor?: string
  countBorderColor?: string
  animated?: boolean
}

const PictureAvatarGrouping: React.FC<PictureAvatarGroupingProps> = ({
  pictures = [],
  picturesCount = 0,
  limit = 8,
  showPlaceholders,
  countBgColor,
  countBorderColor,
  size,
  animated,
  ...props
}) => {
  const totalCount = Math.max(picturesCount, pictures.length)
  const overflowCount = totalCount - limit
  const overflowDisplayCount = Math.min(99, overflowCount)

  const container: Variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  }

  const listItem: Variants = {
    hidden: { opacity: 0, y: '50%', transition: { type: 'spring' } },
    show: { opacity: 1, y: 0, transition: { type: 'spring' } },
  }

  return (
    <AnimatePresence>
      <motion.div
        variants={container}
        initial={animated ? 'hidden' : false}
        animate="show"
        className={clsx('flex', {
          'space-x-[-8px]': size === 'tiny',
          'space-x-[-6px]': size !== 'tiny',
        })}
      >
        {pictures.slice(0, limit).map((picture, index) => (
          <motion.div
            key={`${picture}.${index}`}
            variants={listItem}
            style={{ zIndex: limit - index }}
          >
            <PictureAvatar picture={picture} size={size} {...props} />
          </motion.div>
        ))}
        {showPlaceholders && pictures.length < limit && (
          <>
            <motion.div variants={listItem} style={{ zIndex: 1 }}>
              <PictureAvatar
                bgColor="bg-[#EBEBE1]"
                border
                borderColor="border-gray-400"
                borderStyle="border-dashed"
                size={size}
              >
                <span className="text-xs font-semibold text-gray-400">+</span>
              </PictureAvatar>
            </motion.div>
            <motion.div variants={listItem} style={{ zIndex: 0 }}>
              <PictureAvatar
                bgColor="bg-[#EBEBE1]"
                border
                borderColor="border-gray-400"
                borderStyle="border-dashed"
                size={size}
              >
                <span className="text-xs font-semibold text-gray-400">+</span>
              </PictureAvatar>
            </motion.div>
          </>
        )}
        {overflowCount > 0 && (
          <motion.div variants={listItem} style={{ zIndex: 0 }}>
            <PictureAvatar
              bgColor={countBgColor}
              borderColor={countBorderColor}
              size={size}
            >
              <span className="text-xxs">
                {overflowDisplayCount > 99 ? '99+' : overflowDisplayCount}
              </span>
            </PictureAvatar>
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default PictureAvatarGrouping
