import React, { useEffect } from 'react'
import ModalDialog from '@carrotcart/client-common/components/ModalDialog'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'

interface SignUpModalProps {
  size?: ReturnType<typeof ModalDialog>['props']['size']
  dismissOnSignup?: boolean
  disableClose?: boolean
  callback?: (loggedIn?: boolean) => Promise<void> | void
}

const SignUpModal: React.FC<SignUpModalProps> = ({
  children,
  size,
  dismissOnSignup = true,
  disableClose,
  callback,
}) => {
  const { currentUser, isAnAnonymousAuthUser } = useWebAppContext()

  const {
    showSignupWall,
    closeSignupWall,
    showMiniOnboarding,
    signupWallParams: { disableBgClick },
  } = useSignupWallContext()

  useEffect(() => {
    if (
      dismissOnSignup &&
      showSignupWall &&
      currentUser &&
      !isAnAnonymousAuthUser &&
      !showMiniOnboarding
    ) {
      closeSignupWall()
    }
  }, [
    dismissOnSignup,
    showSignupWall,
    currentUser,
    closeSignupWall,
    showMiniOnboarding,
    isAnAnonymousAuthUser,
  ])

  if (!showSignupWall) return null

  return (
    <ModalDialog
      size={size}
      autoOpen
      disableClose={disableClose || disableBgClick}
      showXClose={!disableClose}
      onClose={() => {
        closeSignupWall()
        callback && callback(!!currentUser)
      }}
    >
      {() => <div>{children}</div>}
    </ModalDialog>
  )
}

export default SignUpModal
