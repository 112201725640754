import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  CartItemDataFragment,
  Cart_Constraint,
  Cart_Item_Insert_Input,
  Cart_Update_Column,
  Collection,
  ItemDataFragment,
  Shop_Constraint,
  Shop_Item_Constraint,
  Shop_Item_Update_Column,
  Shop_Update_Column,
  SimilarProductOutput,
  useCopyItemToCartMutation,
  useSaveCartWithSeparatedRemovalsMutation,
} from '@carrotcart/data/generated'
import { DiscoveryCollectionCartItemFragment } from '@carrotcart/data/graphql/fragments.generated'
import { useFindShopItemAndShopByUrlLazyQuery } from '@carrotcart/app/data/FindShopItemAndShopByUrl.generated'
import logger from '@carrotcart/client-common/lib/logger/web'
import {
  getShopHostname,
  getShopKey,
  getShopName,
  getShopSlug,
} from '@carrotcart/common/lib/shopUtils'
import {
  AnalyticsEventName,
  MessageListenerRuntime,
} from '@carrotcart/common/lib/constants'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import PostWindowMessage, {
  PostWindowMessageType,
} from '@carrotcart/client-common/lib/PostWindowMessage'
import BookmarkIcon from '@carrotcart/app/components/svg/BookmarkIcon'
import BookmarkIconFilled from '@carrotcart/app/components/svg/BookmarkIconFilled'

interface AddToCarrotButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  similarProduct?: SimilarProductOutput
  shopItem?: ItemDataFragment | undefined
  collectionId?: Collection['id'] | undefined
  cartItem?:
    | CartItemDataFragment
    | DiscoveryCollectionCartItemFragment['cart_item']
    | undefined
  page: string
  size?: 'small' | 'large' | 'xlarge'
  type?: 'hover' | 'block'
  callback?: () => Promise<void> | void
}

const AddToCarrotButton: React.FC<AddToCarrotButtonProps> = ({
  className,
  similarProduct,
  shopItem,
  collectionId,
  cartItem,
  page,
  size = 'large',
  type = 'hover',
  callback,
}) => {
  const { currentUser } = useWebAppContext()
  const { openSignupWall, closeSignupWall } = useSignupWallContext()
  const [shouldCopyItemToCart, setShouldCopyItemToCart] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [shopItemToCopy, setShopItemToCopy] =
    useState<ItemDataFragment | null>(shopItem)

  const [findItemAndShopByUrl] = useFindShopItemAndShopByUrlLazyQuery()
  const [saveCart] = useSaveCartWithSeparatedRemovalsMutation()
  const [copyItemMutation] = useCopyItemToCartMutation()

  const copyItemToCart = useCallback(async () => {
    try {
      const copyItemResponse = await copyItemMutation({
        variables: {
          itemId: shopItemToCopy.id,
          shopKey: shopItemToCopy.shop_key,
          source: 'copy_item',
          metadata: cartItem
            ? {
                originating_user_id: cartItem?.cart?.user?.id,
                originating_cart_item_id: cartItem?.id,
                originating_collection_id: collectionId || null,
              }
            : undefined,
        },
      })

      if (copyItemResponse.errors) {
        logger.error({
          message: 'GQL error copying item to cart',
          errors: copyItemResponse.errors,
        })
        return
      }

      analytics.track(AnalyticsEventName.CartSaved, {
        source: 'web_app',
        page,
      })

      // ensure we are returning the correct item id, if cart has multiple items
      const newCartItem = (copyItemResponse.data?.cart?.cart_items || []).find(
        (cartItem) => cartItem.item.id === shopItemToCopy.id
      )

      const productImage =
        newCartItem?.item_image_override ||
        shopItemToCopy?.cached_image ||
        shopItemToCopy?.image

      PostWindowMessage.postMessage(
        MessageListenerRuntime.WebApp,
        PostWindowMessageType.AddToCollection,
        {
          cartItemId: newCartItem?.id,
          image: productImage,
          title: shopItemToCopy?.display_title,
        }
      )
    } catch (err) {
      logger.error({
        message: 'error copying item to cart from a collection web view',
        err,
      })
    }
  }, [cartItem, shopItemToCopy, collectionId, copyItemMutation, page])

  const processAndSaveSimilarProduct = useCallback(async () => {
    const url = similarProduct.link

    const shopKey = getShopKey(url)
    const hostname = getShopHostname(url)
    const shopName = getShopName(hostname)
    const shopSlug = getShopSlug(shopName)

    const resp = await findItemAndShopByUrl({ variables: { url, shopSlug } })

    const shopItem = resp.data?.shop_item?.[0]
    const shopResp = shopItem?.shop?.[0] || resp.data?.shop?.[0]

    const shopData = {
      key: shopResp?.key || shopKey,
      type: shopResp?.type || undefined,
      name: shopResp?.name || shopName,
      slug: shopResp?.slug || shopSlug,
      favicon: shopResp?.favicon || similarProduct.source_icon,
    }

    const item_id = shopItem?.item_id || url

    const shop = {
      data: shopData,
      on_conflict: {
        constraint: Shop_Constraint.ShopPkey,
        update_columns: [Shop_Update_Column.Key],
      },
    }

    try {
      const itemData: Cart_Item_Insert_Input = {
        shop,
        quantity: 1,
        source: shopItem?.source || 'web',
        item: {
          data: {
            shop,
            item_id,
            image: shopItem?.image || similarProduct.thumbnail,
            url: shopItem?.url || url,
            source: shopItem?.source || 'web',
          },
          on_conflict: {
            constraint: Shop_Item_Constraint.ShopItemShopKeyItemIdKey,
            update_columns: [
              Shop_Item_Update_Column.ShopKey,
              Shop_Item_Update_Column.ItemId,
              Shop_Item_Update_Column.Title,
              Shop_Item_Update_Column.Url,
              Shop_Item_Update_Column.Image,
              Shop_Item_Update_Column.Pricing,
            ],
          },
        },
        cart: {
          data: {
            shop,
          },
          on_conflict: {
            constraint: Cart_Constraint.CartUserIdShopKeyKey,
            update_columns: [Cart_Update_Column.ShopKey],
          },
        },
      }

      const cartItemsData = itemData

      const saveCartResp = await saveCart({
        variables: {
          userId: currentUser?.id,
          shopKey,
          cart_items_data: cartItemsData,
        },
      })

      const cartItemAdded = saveCartResp?.data?.cart_items.returning?.[0]

      if (cartItemAdded) {
        setShopItemToCopy(cartItemAdded.item)
        setShouldCopyItemToCart(true)
      }
    } catch (e) {
      logger.error({
        message: 'Error saving similar item to Carrot',
        url,
        e,
      })
    }
  }, [currentUser?.id, findItemAndShopByUrl, saveCart, similarProduct])

  // reset willCopyItemToCart state when item changes
  useEffect(() => {
    setShouldCopyItemToCart(false)
  }, [shopItemToCopy?.id])

  /* ensure we have a current user before copying item to cart
  this is to ensure we don't run an unnecessary mutation
  when we already have the cart item for the user
  */
  useEffect(() => {
    if (!shouldCopyItemToCart) return
    if (!currentUser) return
    ;(async () => {
      await copyItemToCart()
      setShouldCopyItemToCart(false)
      setProcessing(false)
    })()
  }, [shouldCopyItemToCart, currentUser, copyItemToCart])

  const clickEvent = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    if (
      processing ||
      (shopItemToCopy && (!shopItemToCopy.id || !shopItemToCopy.shop_key)) ||
      (similarProduct && !similarProduct.link)
    ) {
      return
    }

    const currentUserLoggedIn = !!currentUser?.id

    analytics.track(AnalyticsEventName.ClickedAddToCarrot, {
      source: page,
      user_anonymous: !currentUserLoggedIn,
      creator_id: cartItem?.cart?.user?.id,
    })

    // flow for not logged in users
    if (!currentUserLoggedIn) {
      openSignupWall({
        type: 'with_custom_callback',
        customCopy: 'Get your very own Carrot link!',
        cartItem:
          cartItem || ({ item: shopItemToCopy } as CartItemDataFragment),
        trackingData: {
          source: 'add_to_carrot',
          trigger: 'click',
        },
        callback: async () => {
          // trigger copy item to cart
          if (!shopItemToCopy && similarProduct) {
            await processAndSaveSimilarProduct()
          } else {
            setShouldCopyItemToCart(true)
          }
          // clear the signup callback
          // OPEN QUESTION: should this trigger the minionboarding flow?
          closeSignupWall()

          if (callback) {
            await callback()
          }
        },
      })
      return
    }

    if (!shopItemToCopy && similarProduct) {
      setProcessing(true)
      await processAndSaveSimilarProduct()
    } else {
      // flow for logged in users
      setProcessing(true)
      await copyItemToCart()
      setProcessing(false)
    }
  }

  // use switch case to set button size
  const buttonSize = (() => {
    switch (size) {
      case 'small':
        return 28
      case 'large':
        return 40
      case 'xlarge':
        return 44
    }
  })()

  const iconSize = (() => {
    switch (size) {
      case 'small':
        return 14
      case 'large':
        return 16
      case 'xlarge':
        return 20
    }
  })()

  if (type === 'block') {
    return (
      <button
        type="button"
        className={clsx(
          'flex items-center justify-center space-x-1 shrink-0 drop-shadow hover:drop-shadow-lg text-white bg-purple hover:bg-purple-600 rounded-full transition-all',
          {
            'opacity-80': processing,
            'px-3 h-[28px]': size === 'small',
            'px-4 h-[40px]': size === 'large',
            'px-4 h-[44px]': size === 'xlarge',
          },
          className
        )}
        style={{
          height: buttonSize,
        }}
        onClick={clickEvent}
      >
        {shopItem?.saved_by_current_user ? (
          <BookmarkIconFilled width={iconSize} height={iconSize} color="#fff" />
        ) : (
          <BookmarkIcon width={iconSize} height={iconSize} color="#fff" />
        )}

        <span className="hidden md:block">
          {shopItem?.saved_by_current_user ? 'Saved' : 'Save'}
        </span>
      </button>
    )
  }

  return (
    <button
      type="button"
      className={clsx(
        'flex items-center justify-center absolute z-10 md:group-hover:opacity-100 drop-shadow hover:drop-shadow-lg bg-container hover:bg-white rounded-full transition-all pointer-events-none md:pointer-events-auto',
        {
          'text-black/50 hover:text-black/80': !shopItem?.saved_by_current_user,
          'text-black/100': shopItem?.saved_by_current_user,
          'opacity-80': processing,
          'opacity-100 pointer-events-none': !processing,
          'bottom-2 right-2 w-[28px] h-[28px]': size === 'small',
          'bottom-3 right-3 w-[40px] h-[40px]': size === 'large',
        },
        className
      )}
      style={{
        height: buttonSize,
      }}
      onClick={clickEvent}
    >
      {shopItem.saved_by_current_user ? (
        <BookmarkIconFilled width={iconSize} height={iconSize} />
      ) : (
        <BookmarkIcon width={iconSize} height={iconSize} />
      )}
    </button>
  )
}

export default AddToCarrotButton
