import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { motion, AnimatePresence } from 'framer-motion'
import { useCreateFreeCreatorSubscriptionMutation } from '@carrotcart/app/data/subscribe/createFreeCreatorSubscription.generated'
import type { SignupChildProps } from './types'
import ProgressLinear from '@carrotcart/client-common/components/ProgressLinear'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupWallContext } from '@carrotcart/app/context/SignupWallProvider'
import { usePremiumContentContext } from '@carrotcart/app/context/PremiumContentProvider'
import PictureAvatar from '@carrotcart/app/components/PictureAvatar'
import { FindCollectionType } from '@carrotcart/app/types'

interface Props extends SignupChildProps {
  collection: FindCollectionType
}

const SignupWithPremiumContent: React.FC<Props> = ({
  miniOnboard,
  providerLogin,
  collection,
}) => {
  const router = useRouter()
  const { authUser } = useAuthUser()
  const { loadingApp, currentUser } = useWebAppContext()
  const { closeSignupWall } = useSignupWallContext()
  const [providerLoginVisible, setProviderLoginVisible] = useState(false)

  const user = collection?.user

  const [subscribeMutation, { loading: subscribing }] =
    useCreateFreeCreatorSubscriptionMutation({
      variables: {
        creatorId: user?.id,
      },
      fetchPolicy: 'no-cache',
    })

  const { subscriptionStatus, refetchCreatorSubscriptions, hasBillingAccount } =
    usePremiumContentContext()

  const avatarSrc = user?.picture

  const paidSubscription = subscriptionStatus === 'paid'
  const freeSubscription = subscriptionStatus === 'unpaid'
  const notSubscribed = subscriptionStatus === 'none'

  const shouldRedirectToSubscribe =
    hasBillingAccount && (notSubscribed || freeSubscription)

  const redirectToSubscribe = useCallback(() => {
    router.push(
      `/${user?.username}/subscribe?redirect=${encodeURIComponent(
        router.asPath
      )}`
    )
  }, [router, user?.username])

  const toggleProviderLogin = () => {
    setProviderLoginVisible(!providerLoginVisible)
  }

  const subscribeToCreator = async (e) => {
    e.preventDefault()

    if (!authUser) {
      e.preventDefault()
      setProviderLoginVisible(true)
    } else {
      await subscribeMutation()

      if (shouldRedirectToSubscribe) {
        redirectToSubscribe()
      } else {
        await refetchCreatorSubscriptions()
      }
    }
  }

  useEffect(() => {
    if (paidSubscription) {
      closeSignupWall()
    }
  }, [closeSignupWall, paidSubscription])

  useEffect(() => {
    if (!currentUser) return
    if (!providerLoginVisible) return
    if (!shouldRedirectToSubscribe) return
    ;(async () => {
      if (notSubscribed) {
        await subscribeMutation()
      }

      redirectToSubscribe()
    })()
  }, [
    currentUser,
    notSubscribed,
    providerLoginVisible,
    redirectToSubscribe,
    shouldRedirectToSubscribe,
    subscribeMutation,
    refetchCreatorSubscriptions,
  ])

  return (
    <div className="flex flex-col md:flex-row mx-auto bg-white rounded-lg overflow-hidden relative">
      <div className={clsx('absolute z-10 top-0 inset-x-0')}>
        <ProgressLinear visible={loadingApp} />
      </div>
      <div className="flex flex-col justify-center items-center px-6 py-8 md:px-28 md:py-20">
        <div className="flex-1 flex flex-col justify-center items-center space-y-8">
          {miniOnboard}

          <div className="flex flex-col items-center text-center space-y-4">
            <PictureAvatar picture={avatarSrc} rounded size="onboarding" />

            <div className="mx-auto max-w-sm font-bold text-center text-3xl">
              This collection is for premium subscribers
            </div>
            <div className="text-gray mx-auto max-w-xs text-lg">
              Unlock{' '}
              <span className="px-1 bg-premium text-premium-badge">
                premium
              </span>{' '}
              content for $5 per month. Get one free month.
            </div>
          </div>

          <div
            className={clsx('w-full relative transition-all', {
              'min-h-[110px]': !providerLoginVisible,
              'min-h-[260px]': providerLoginVisible,
            })}
          >
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 1 }}
                animate={
                  providerLoginVisible ? { display: 'none' } : { opacity: 1 }
                }
                className={clsx('flex flex-col items-center space-y-6', {
                  'opacity-0': providerLoginVisible,
                })}
              >
                <button
                  type="button"
                  disabled={subscribing}
                  className={clsx(
                    'inline-flex mr-2 items-center justify-center text-xl font-semibold h-16 px-10 rounded-full whitespace-nowrap transition-all',
                    'bg-skin-primary text-skin-a11y hover:bg-skin-primary-dark'
                  )}
                  onClick={subscribeToCreator}
                >
                  {freeSubscription
                    ? 'Upgrade'
                    : authUser
                    ? 'Subscribe'
                    : 'Sign up & Subscribe'}
                </button>

                {authUser ? (
                  <Link
                    href={`/${user?.username}`}
                    className="text-gray text-sm hover:underline"
                  >
                    No thanks
                  </Link>
                ) : (
                  <div className="space-y-4 text-center">
                    <Link
                      href={`/${user?.username}`}
                      className="block text-gray text-sm hover:underline"
                    >
                      No thanks
                    </Link>
                    <button onClick={toggleProviderLogin}>
                      <span>Already a subscriber?</span>{' '}
                      <span className="font-semibold underline">Sign in</span>
                    </button>
                  </div>
                )}
              </motion.div>

              <div
                className={clsx(
                  'absolute inset-0 flex flex-col items-center w-full',
                  {
                    'pointer-events-none': !providerLoginVisible,
                  }
                )}
              >
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={
                    providerLoginVisible
                      ? { opacity: 1, y: 0 }
                      : { display: 'none' }
                  }
                  className="flex flex-col items-center space-y-4"
                >
                  {providerLogin}

                  {!authUser && (
                    <button
                      className="text-gray-400 underline"
                      onClick={toggleProviderLogin}
                    >
                      Back
                    </button>
                  )}
                </motion.div>
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupWithPremiumContent
