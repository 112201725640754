import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '@carrotcart/client-common/assets/images/extension/search-icon.svg'

interface Props {
  totalCollections: number
  loadingCollections: boolean
  filterCollections?: React.Dispatch<React.SetStateAction<string>>
  searchQuery?: string
}
const FilterCollections: React.FC<Props> = ({
  totalCollections,
  loadingCollections,
  filterCollections,
  searchQuery,
}) => {
  const [query, setQuery] = useState('')
  const [tmpQuery, setTmpQuery] = useState('')
  const [prevQuery, setPrevQuery] = useState('')

  const delayDebounceFnRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (delayDebounceFnRef.current) clearTimeout(delayDebounceFnRef.current)

    delayDebounceFnRef.current = setTimeout(() => {
      setQuery(tmpQuery)
    }, 400)

    return () => {
      if (delayDebounceFnRef.current) clearTimeout(delayDebounceFnRef.current)
    }
  }, [tmpQuery])

  useEffect(() => {
    if (query === prevQuery) return

    if (!query) {
      filterCollections('%%')
      return
    }
    setPrevQuery(query)
    filterCollections(`%${query}%`)
  }, [prevQuery, query, filterCollections])

  // detect when enter is pressed
  const searchField = useRef<HTMLInputElement>(null)
  // chekc if enter is pressed when search field is focused
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && searchField.current === document.activeElement) {
        const query = tmpQuery || ''
        setQuery(query)
        setPrevQuery(query)
        filterCollections(`%${query}%`)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [filterCollections, tmpQuery])

  // if less than 4 collection & no query, don't show filter
  if (totalCollections < 4 && !searchQuery && !loadingCollections) return null

  return (
    <>
      <div className="flex w-full flex-row items-center border-b pb-2 mb-2 border-[#BEBEBE]">
        <SearchIcon className="min-w-[14px] ml-2" />
        <input
          type="text"
          value={tmpQuery}
          placeholder="Filter"
          onChange={({ target }) => setTmpQuery(target.value)}
          className="ml-2 text-sm text-onpagegray outline-none"
        />
      </div>

      {totalCollections === 0 && (
        <div className="text-center text-onpagegray text-sm my-4">
          {loadingCollections ? 'Loading...' : 'No collections found'}
        </div>
      )}
    </>
  )
}

export default FilterCollections
