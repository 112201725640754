import React, { Fragment, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Dialog, Transition } from '@headlessui/react'
import logger from '@carrotcart/client-common/lib/logger/web'
import {
  AnalyticsEventName,
  DATA_SOURCE_CARROT,
} from '@carrotcart/common/lib/constants'
import ProgressLinear from '@carrotcart/app/components/ProgressLinear'
import NotificationAddToCollection from '@carrotcart/app/components/atc-button/NotificationAddToCollection'
import {
  CollectionAndItemsDataFragment,
  GetUserCreatedCollectionsDocument,
  useAddNewCollectionMutation,
  useAddToCollectionMutation,
  useGetUserCreatedCollectionsQuery,
} from '@carrotcart/data/generated'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import slugifyName from '@carrotcart/common/lib/slugifyName'
import useWebAppNotification from '@carrotcart/app/hooks/useWebAppNotification'
//
import {
  PostWindowMessageType,
  TPostMessage,
} from '@carrotcart/client-common/lib/PostWindowMessage'
import CarrotLogo from '@carrotcart/client-common/assets/images/extension/carrot-logo.svg'
import SettingsIcon from '@carrotcart/client-common/assets/images/extension/settings-icon.svg'
import CloseIcon from '@carrotcart/client-common/assets/images/extension/close-icon.svg'
import { useRouter } from 'next/router'

interface SavedItemData {
  cartItemId: string
  image: string
  title: string
}

const AddCartItemToCollection: React.FC = () => {
  const {
    notificationState,
    triggerNotification,
    pauseNotificationTimer,
    resumeNotificationTimer,
    dismissNotification,
    activateNotification,
  } = useWebAppNotification()

  const router = useRouter()
  const { currentUser, clientAuthorized } = useWebAppContext()
  const [addNewCollection] = useAddNewCollectionMutation()
  const [addToCollection] = useAddToCollectionMutation()
  const [searchQuery, setSearchQuery] = useState('%%')
  const [cartItem, setCartItem] = useState<SavedItemData>({
    cartItemId: '',
    image: '',
    title: '',
  })

  const {
    data: userCollections,
    fetchMore,
    loading: loadingCollections,
    refetch: refetchCollections,
  } = useGetUserCreatedCollectionsQuery({
    variables: {
      userId: currentUser?.id,
      withOwner: true,
      offset: 0,
      limit: 10,
      searchQuery,
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentUser?.id || !clientAuthorized || !cartItem?.cartItemId,
  })

  const [showNewCollection, setShowNewCollection] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [prevCartItemId, setPrevCartItemId] = useState<string | null>(
    cartItem?.cartItemId
  )
  const [itemImageError, setItemImageError] = useState(false)

  const loading = loadingCollections || notificationState.loading || processing

  const collections = (userCollections?.collections ||
    []) as unknown as CollectionAndItemsDataFragment[]

  const show = !!notificationState.display && !!cartItem?.cartItemId

  const fetchMoreCollections = useCallback(async () => {
    const totalCollectionsCount =
      userCollections?.collection_aggregate?.aggregate?.count
    if (totalCollectionsCount === userCollections?.collections?.length) return

    await fetchMore({
      variables: {
        offset: userCollections.collections.length,
      },
    })
  }, [
    userCollections?.collection_aggregate?.aggregate?.count,
    userCollections?.collections?.length,
    fetchMore,
  ])

  // addToCollection can take in a collection_id which
  // it uses with the cartItemId to add the item to that collection
  const addToCollectionFunc = async (collection_id: string) => {
    if (processing) return
    if (!cartItem.cartItemId) return

    // show loading indicator
    setProcessing(true)

    const data = {
      id: collection_id,
      cart_item_ids: [cartItem.cartItemId],
      source: 'webapp-notification',
    }

    const resp = await addToCollection({
      variables: {
        objects: data.cart_item_ids.map((id) => ({
          collection_id: data.id,
          cart_item_id: id,
        })),
      },
    })
    analytics.track(AnalyticsEventName.AddedToCollection, data)

    if (resp.data.collectionCartItem && !resp.errors) {
      //success
      setProcessing(false)
    }
    // notification will close when done button pressed
    // but the user can only select one collection
    dimiss()
  }

  const createNewCollection = async (
    collectionName: string,
    isPrivate: boolean
  ) => {
    if (processing) return

    if (collectionName) {
      // show loading indicator
      setProcessing(true)
      const data = {
        name: collectionName,
        private: isPrivate,
        source: 'webapp-notification',
      }

      const resp = await addNewCollection({
        variables: {
          name: data.name,
          private: data.private,
          slug: slugifyName(data.name),
        },
        refetchQueries: [GetUserCreatedCollectionsDocument],
      })
      analytics.track(AnalyticsEventName.CreatedCollection, data)

      if (resp.data?.collection) {
        setProcessing(false)
        // process new collection
        // setCreatedCollection(resp.data.collection)
        // display new collection as selected
        setShowNewCollection(true)

        // add item to new collection
        await addToCollectionFunc(resp.data.collection.id)
      }
    }
  }

  const processFormSubmit = async (
    form: HTMLFormElement,
    pressedEnter = false
  ) => {
    const formData = {
      note: '',
      createCollectionName: '',
      createCollectionPrivate: false,
      selectedCollectionId: '',
    }

    if (form.elements.namedItem('note')) {
      formData.note = (
        form.elements.namedItem('note') as HTMLTextAreaElement
      ).value.trim()
    }
    if (form.elements.namedItem('selectedCollectionId')) {
      formData.selectedCollectionId = (
        form.elements.namedItem('selectedCollectionId') as HTMLTextAreaElement
      ).value.trim()
    }
    if (form.elements.namedItem('createCollectionName')) {
      formData.createCollectionName = (
        form.elements.namedItem('createCollectionName') as HTMLInputElement
      ).value.trim()
    }
    if (form.elements.namedItem('createCollectionPrivate')) {
      formData.createCollectionPrivate = (
        form.elements.namedItem('createCollectionPrivate') as HTMLInputElement
      ).checked
    }

    if (!formData?.selectedCollectionId && !formData?.createCollectionName)
      return

    // logic - if only note is returned then wait another two seconds before closing
    // if creating a collection return after creating as that will also add into a collection
    // if a user has created a collection and saved a note we should close the notification

    // user has created a collection create it and return
    if (formData.createCollectionName) {
      await createNewCollection(
        formData.createCollectionName,
        formData.createCollectionPrivate
      )
      return
    }

    // user has selected a collection add to it
    if (formData.selectedCollectionId) {
      await addToCollectionFunc(formData.selectedCollectionId)
    }

    // if the user pressed enter to save a note close
    if (pressedEnter) {
      dimiss()
      return
    }

    // if all events have completed wait another 300ms before closing
    setTimeout(() => {
      dimiss()
    }, 300)
  }

  const addNote = () => {
    router.replace(
      `/${currentUser?.username}?cartItemId=${cartItem?.cartItemId}#items/all`
    )
  }

  const dimiss = () => {
    dismissNotification()
    setItemImageError(false)
    setCartItem(null)
  }

  useEffect(() => {
    const handleOnPageMessage = (event: any) => {
      try {
        const { action, source, message } = event?.data as TPostMessage

        if (source !== DATA_SOURCE_CARROT) return
        switch (action) {
          case PostWindowMessageType.AddToCollection: {
            const data = message as SavedItemData

            setCartItem(data)
            triggerNotification(true)
            activateNotification()
          }
        }
      } catch (err) {
        logger.error({
          message: 'error parsing message event',
          err,
        })
      }
    }

    window.addEventListener('message', handleOnPageMessage)

    return () => {
      window.removeEventListener('message', handleOnPageMessage)
    }
  }, [activateNotification, triggerNotification])

  useEffect(() => {
    // reset saving note when cart item changes
    if (!cartItem?.cartItemId) return
    if (cartItem?.cartItemId === prevCartItemId) return

    setPrevCartItemId(cartItem.cartItemId)
    setShowNewCollection(false)
    refetchCollections()
  }, [cartItem?.cartItemId, prevCartItemId, refetchCollections])

  if (!cartItem?.cartItemId) return null

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={dimiss}>
        <div className="min-h-screen px-2 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/10" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <form
              onSubmit={async (e) => {
                e.preventDefault()
                // get input vales from the form
                const form = e.target as HTMLFormElement
                processFormSubmit(form)
              }}
              onMouseLeave={resumeNotificationTimer}
              onMouseEnter={pauseNotificationTimer}
              className="z-[2147483647] fixed top-20 right-4"
            >
              <div className="relative flex flex-col items-center overflow-hidden bg-white rounded-lg shadow-2xl px-3 pt-3 pb-5 w-[240px] transistion-width duration-300 font-sans">
                <div className="w-full flex flex-row justify-between items-center mb-4">
                  <CarrotLogo />
                  <div className="flex items-center space-x-1">
                    <div
                      className="cursor-pointer transition-opacity opacity-60 hover:opacity-100"
                      onClick={async () => {
                        // open settings page in web app
                        router.push('/settings')
                      }}
                    >
                      <SettingsIcon />
                    </div>

                    <div
                      className="cursor-pointer transition-opacity opacity-60 hover:opacity-100"
                      onClick={dimiss}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex w-full flex-col justify-center items-center">
                    <div className="group relative mb-6">
                      <div className="relative mb-4 w-[140px]">
                        {!itemImageError ? (
                          <img
                            className="flex rounded-lg overflow-hidden bg-[#E3E3E3] max-w-[140px] min-h-[150px] object-cover bg-blend-multiply"
                            src={cartItem.image}
                            onError={(_e) => {
                              setItemImageError(true)
                              // TODO: log error
                            }}
                          />
                        ) : (
                          <div className="flex rounded-lg overflow-hidden bg-[#E3E3E3] min-w-[140px] max-w-[140px] min-h-[150px] text-slate items-center justify-center text-xs">
                            No Image
                          </div>
                        )}
                        <div className="absolute top-0 left-0 rounded-lg overflow-hidden bg-[#E3E3E3] h-full w-full mix-blend-multiply opacity-50" />
                      </div>
                      <div className="flex absolute top-0 left-0 w-full h-full flex-col justify-center items-center opacity-0 group-hover:opacity-100 space-y-2 transition-opacity">
                        <div
                          onClick={addNote}
                          className="text-center font-medium w-[120px] text-sm py-3 rounded-full bg-onpagegray2 hover:bg-container transition-colors text-onpagegray cursor-pointer"
                        >
                          Add Note
                        </div>
                      </div>
                    </div>

                    <div className={clsx('absolute bottom-0 inset-x-0')}>
                      <ProgressLinear
                        secondaryColor="bg-gray-300"
                        primaryColor="bg-black"
                        visible={loading}
                      />
                    </div>

                    <NotificationAddToCollection
                      cartItemId={cartItem.cartItemId}
                      collections={collections}
                      showNewCollection={showNewCollection}
                      fetchMoreCollections={fetchMoreCollections}
                      filterCollections={setSearchQuery}
                      loadingCollections={loadingCollections}
                      searchQuery={searchQuery}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
export default AddCartItemToCollection
