import { SVGProps, memo } from 'react'

const HeartIconFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.52 5.38C.52 2.454 3.088.202 6.1.202c1.553 0 2.92 1.728 3.9 2.67.98-.942 2.347-2.67 3.9-2.67 3.013 0 5.58 2.252 5.58 5.178 0 2.004-.855 3.766-2.04 5.256-1.181 1.487-2.728 2.75-4.208 3.775a14.96 14.96 0 0 1-1.66 1.02c-.492.25-1.058.48-1.572.48s-1.08-.23-1.571-.48a14.948 14.948 0 0 1-1.661-1.02c-1.48-1.026-3.027-2.288-4.209-3.775C1.375 9.146.521 7.384.521 5.38ZM6.1 1.827c-2.253 0-3.954 1.656-3.954 3.553 0 1.52.645 2.936 1.686 4.245 1.042 1.311 2.445 2.468 3.861 3.45.536.371 1.041.687 1.473.907.464.237.733.303.834.303.101 0 .37-.066.834-.303.432-.22.937-.536 1.473-.907 1.416-.982 2.819-2.139 3.861-3.45 1.04-1.309 1.686-2.725 1.686-4.245 0-1.897-1.701-3.553-3.954-3.553-1.294 0-2.502 1.773-3.256 2.754a.813.813 0 0 1-1.288 0C8.602 3.6 7.394 1.827 6.1 1.827Z"
      fill="currentColor"
    />
    <path
      d="M6.1 1.827c-2.253 0-3.954 1.656-3.954 3.553 0 1.52.645 2.936 1.686 4.245 1.042 1.311 2.445 2.468 3.861 3.45.536.371 1.041.687 1.473.907.464.237.733.303.834.303.101 0 .37-.066.834-.303.432-.22.937-.536 1.473-.907 1.416-.982 2.819-2.139 3.861-3.45 1.04-1.309 1.686-2.725 1.686-4.245 0-1.897-1.701-3.553-3.954-3.553-1.294 0-2.502 1.773-3.256 2.754a.813.813 0 0 1-1.288 0C8.602 3.6 7.394 1.827 6.1 1.827Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(HeartIconFilled)
export default Memo
