const DEFAULT_CURRENCY = 'USD'
const CURRENCY_CODE_REGEX = /^[a-z]{3}$/i

const formatItemPrice = (
  price: string | number = 0,
  {
    currency = DEFAULT_CURRENCY,
    fallback = 'See price',
    showFallback = true,
  }: { currency?: string; fallback?: string; showFallback?: boolean } = {}
): string | undefined => {
  const fbk = showFallback ? fallback : undefined
  try {
    return Number(price) > 0
      ? Number(price).toLocaleString('en-US', {
          style: 'currency',
          currency: CURRENCY_CODE_REGEX.test(currency || '')
            ? currency.toUpperCase()
            : DEFAULT_CURRENCY,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : fbk
  } catch (err) {
    return Number(price) > 0
      ? Number(price).toLocaleString('en-US', {
          style: 'currency',
          currency: DEFAULT_CURRENCY,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : fbk
  }
}

export const formatPriceSpace = (str: string | number = 0): string => {
  str = str.toString()
  const regex = /^[A-Z]{2,3}(?=[^A-Za-z])/i
  str = str.replace(/\s/g, '')
  const matches = regex.exec(str)
  if (matches) {
    const match = matches[0]
    str = str.slice(0, match.length) + ' ' + str.slice(match.length)
  }
  return str
}

export default formatItemPrice
