import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import human from 'humanparser'
import { useForm } from 'react-hook-form'
import {
  AnalyticsEventName,
  FALLBACK_DISPLAY_NAME,
} from '@carrotcart/common/lib/constants'
import { useUpdateUserNameAndUsernameMutation } from '@carrotcart/data/generated'
import useClaimUsername from '@carrotcart/app/hooks/useClaimUsername'
import FullnameInput from '@carrotcart/app/components/onboarding/FullnameInput'
import UsernameInput from '@carrotcart/app/components/onboarding/UsernameInput'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupContext } from '@carrotcart/app/context/SignupProvider'
import Button from '@carrotcart/client-common/components/Button'
import { BasicUserDataFragment } from '@carrotcart/data/graphql/fragments.generated'
import PictureAvatar from './PictureAvatar'
import VerifiedIcon from './svg/VerifiedIcon'

type InputData = {
  name: string
  username: string
}

interface Props {
  creator?: BasicUserDataFragment
  callback?: () => void | Promise<void>
}

const MiniOnboard: React.FC<Props> = ({ callback, creator, children }) => {
  const { currentUser, authUser, refetchCurrentUser } = useWebAppContext()
  const { hasSetFullName } = useSignupContext()

  const [processing, setProcessing] = useState(false)
  const [validationErrorMessage, setValidationErrorMessage] = useState<string>()

  const [validateUsername, { invalid, hasClaimedUsername }] = useClaimUsername()

  const firstName = creator
    ? human.parseName(creator.name).firstName
    : undefined

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<InputData>({
    defaultValues: {
      name: authUser?.displayName,
      username: '',
    },
  })

  const [updateUser] = useUpdateUserNameAndUsernameMutation()

  const onSubmit = async (data: InputData) => {
    if (processing || !data.username) return

    setProcessing(true)

    const { respData, errorMessage } = await validateUsername(data.username)
    const { valid, taken } = respData || {}

    setProcessing(false)

    if (errorMessage) {
      setValidationErrorMessage(errorMessage)
      setFocus('username')
      return
    }

    analytics.track(AnalyticsEventName.OnboardingUsernameClaimed, {
      miniOnboard: true,
      username: data.username,
    })

    if (valid && !taken) {
      const resp = await updateUser({
        variables: {
          id: authUser.uid,
          username: data.username,
          name: data.name,
        },
      })

      if (resp.errors) {
        setProcessing(false)
        return
      }

      refetchCurrentUser()

      if (callback) {
        await callback()
      }
    }
  }

  useEffect(() => {
    if (currentUser?.username && hasClaimedUsername) {
      setValue('username', currentUser.username)
    }
    if (currentUser?.name && currentUser.name !== FALLBACK_DISPLAY_NAME) {
      setValue('name', currentUser.name)
    }
  }, [currentUser?.name, currentUser?.username, hasClaimedUsername, setValue])

  useEffect(() => {
    if (authUser) {
      setValue('name', authUser.displayName)
    }
  }, [authUser, setValue])

  return (
    <div className="space-y-4 py-4">
      {creator && (
        <div className="flex flex-col items-center mt-[-100px]">
          <div className="rounded-full border-8 border-white">
            <PictureAvatar
              bgColor="transparent"
              picture={creator.picture}
              customSize={100}
            />
          </div>
          <div className="flex items-center space-x-1">
            <span className="font-semibold">@{creator.username}</span>
            {creator.verified && <VerifiedIcon height={16} width={16} />}
          </div>
        </div>
      )}
      <div
        className={clsx(
          'relative z-10 font-bold text-3xl text-center leading-tight px-4'
        )}
      >
        {creator
          ? `Let ${firstName} know who you are`
          : 'Almost there, let us know who you are'}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-2 max-w-[275px] mx-auto"
      >
        <FullnameInput
          className={clsx({
            hidden: hasSetFullName,
          })}
          autoFocus={!hasSetFullName && !authUser?.displayName}
          error={errors.name}
          {...register('name', {
            required: true,
          })}
        />
        <UsernameInput
          small
          invalid={invalid}
          hasValue={hasClaimedUsername}
          processing={processing}
          autoFocus={
            (hasSetFullName || !!authUser?.displayName) && !hasClaimedUsername
          }
          autoComplete="off"
          error={
            validationErrorMessage ||
            (errors?.username?.type === 'required' && 'Username is required')
          }
          {...register('username', {
            required: true,
          })}
        />
        <Button size="large" type="submit" className="w-full">
          Save & Continue
        </Button>
      </form>

      {children}
    </div>
  )
}

export default MiniOnboard
