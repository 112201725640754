import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import Image from 'next/legacy/image'
import imageKitLoader from '@carrotcart/client-common/lib/imageKitLoader'
import type { SignupChildProps } from './types'

const GeneralSignupWallContent: React.FC<SignupChildProps> = ({
  providerLogin,
  miniOnboard,
}) => {
  const { currentUser } = useWebAppContext()

  return (
    <div className="flex lg:max-w-4xl xl:max-w-6xl mx-auto bg-offwhite rounded-lg">
      <div className="md:w-3/5 flex flex-col justify-center items-center py-24 px-6 md:px-12 lg:px-24">
        {!currentUser?.id ? (
          <div className="flex-1 flex flex-col justify-center items-center space-y-8">
            <div className="font-display text-center text-3xl md:text-4xl xl:text-6xl">
              Create &amp; discover more collections like this one!
            </div>

            {providerLogin}
          </div>
        ) : (
          miniOnboard
        )}
      </div>
      <div className="relative hidden md:block w-2/5 pointer-events-none">
        <Image
          loader={imageKitLoader}
          src="signup-wall-bg_Re_YCdJAt.png"
          layout="fill"
          sizes="(max-width: 300px) 100vw, 300px"
        />
      </div>
    </div>
  )
}

export default GeneralSignupWallContent
