import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import type { SignupChildProps } from './types'

interface Props extends SignupChildProps {
  gifteeName: string
  itemImage: string
}

const GiftingSignUpWallContent: React.FC<Props> = ({
  providerLogin,
  gifteeName,
  itemImage,
  miniOnboard,
}) => {
  const { currentUser } = useWebAppContext()

  return (
    <div className="flex flex-col md:flex-row lg:max-w-4xl xl:max-w-6xl mx-auto bg-offwhite rounded-lg overflow-hidden">
      <div className="order-2 md:order-1 lg:w-2/3 flex flex-col justify-center items-center p-10 xl:p-24">
        <div className="flex-1 flex flex-col justify-center items-center space-y-8">
          {!currentUser?.id ? (
            <>
              <div className="text-center space-y-2">
                <div className="max-w-xs sm:max-w-sm lg:max-w-lg mx-auto font-display text-center text-3xl md:text-4xl xl:text-6xl">
                  Reserve this gift for {gifteeName}
                </div>
                <div className="max-w-[400px] mx-auto text-xs md:text-base">
                  Other gift givers won&#39;t see this item as available and you
                  can always change your mind later.
                </div>
              </div>
              {providerLogin}
            </>
          ) : (
            miniOnboard
          )}
        </div>
      </div>
      <div className="order-1 md:order-2 lg:w-1/3 pointer-events-none md:m-10 xl:m-24 ml-0 xl:ml-0 md:rounded-lg overflow-hidden md:shadow">
        <img
          src={itemImage}
          className="block object-cover h-[200px] md:h-full w-full max-w-full"
        />
      </div>
    </div>
  )
}

export default GiftingSignUpWallContent
