import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  EmblaEventType,
  EmblaOptionsType,
  EmblaCarouselType,
} from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { AutoplayOptionsType } from 'embla-carousel-autoplay'
import { DotButton, NextButton, PrevButton } from './CarouselButtons'
import Autoplay from 'embla-carousel-autoplay'

interface CarouselProps {
  items: React.ReactNode[]
  className?: string
  wrapperClassName?: string
  containerClassName?: string
  slideClassName?: string
  navigationClassName?: string
  navButtonClassName?: string
  options?: EmblaOptionsType
  autoplayOptions?: AutoplayOptionsType
  navigation?: boolean
  dots?: boolean
  dotStyle?: 'pill' | 'circular'
  dotClassName?: string
  apiRef?: React.MutableRefObject<EmblaCarouselType | null>
  onInit?: (eventName: EmblaEventType) => void
  onReInit?: (eventName: EmblaEventType) => void
  onScroll?: (eventName: EmblaEventType) => void
  onSettle?: (eventName: EmblaEventType) => void
}

const Carousel: React.FC<CarouselProps> = ({
  items = [],
  className,
  wrapperClassName,
  containerClassName,
  slideClassName,
  navigationClassName,
  navButtonClassName,
  options,
  autoplayOptions,
  navigation = true,
  dots = true,
  dotStyle = 'circular',
  dotClassName,
  apiRef,
  onInit,
  onReInit,
  onScroll,
  onSettle,
}) => {
  const plugins = []
  plugins.push(WheelGesturesPlugin())

  const autoplay = useRef(
    Autoplay({
      ...autoplayOptions,
      rootNode: (emblaRoot) => emblaRoot.parentElement,
    })
  )
  if (autoplayOptions) {
    plugins.push(autoplay.current)
  }

  const [viewportRef, embla] = useEmblaCarousel(options, plugins)
  if (apiRef) {
    apiRef.current = embla
  }
  const canScrollNext = !!embla?.canScrollNext()
  const canScrollPrev = !!embla?.canScrollPrev()

  const [scrollSnapList, setScrollSnapList] = useState<number[]>([])
  const [selectedScrollSnap, setSelectedScrollSnap] = useState(
    embla?.selectedScrollSnap() || 0
  )

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  )

  useEffect(() => {
    if (!embla) return
    const itemsCount = items.length
    const slideNodesCount = (embla?.slideNodes() || []).length

    if (slideNodesCount !== itemsCount) {
      embla.reInit(options)
    }

    embla.on('select', () => {
      setSelectedScrollSnap(embla.selectedScrollSnap())
    })

    // we need to update state here to trigger re-render
    // otherwise the the carousel does not pick up changes
    setScrollSnapList(embla.scrollSnapList().filter((entry) => !isNaN(entry)))
  }, [embla, items.length, options])

  useEffect(() => {
    if (!embla) return
    if (onInit) {
      embla.on('init', onInit)
    }
  }, [embla, onInit])

  useEffect(() => {
    if (!embla) return
    if (onReInit) {
      embla.on('reInit', onReInit)
    }
  }, [embla, onReInit])

  useEffect(() => {
    if (!embla) return
    if (onScroll) {
      embla.on('scroll', onScroll)
    }
  }, [embla, onScroll])

  useEffect(() => {
    if (!embla) return
    if (onSettle) {
      embla.on('settle', onSettle)
    }
  }, [embla, onSettle])

  return (
    <div className={wrapperClassName}>
      <div
        className={clsx(
          'embla__viewport flex flex-col relative w-full overflow-hidden',
          className
        )}
        ref={viewportRef}
      >
        <div
          className={clsx(
            'embla__container flex-1 flex select-none',
            containerClassName
          )}
        >
          {items.map((item, index) => (
            <div
              key={`item-${index}`}
              className={clsx('embla__slide relative', slideClassName)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      {navigation && (canScrollPrev || canScrollNext) && (
        <div
          className={clsx(
            'embla__nav absolute inset-x-0 max-w-[100vw]',
            navigationClassName
          )}
        >
          <PrevButton
            onClick={scrollPrev}
            enabled={canScrollPrev}
            className={navButtonClassName}
          />
          <NextButton
            onClick={scrollNext}
            enabled={canScrollNext}
            className={navButtonClassName}
          />
        </div>
      )}
      {dots && !!scrollSnapList.length && (
        <div
          className={clsx(
            'embla__dots max-w-[100vw] min-h-[40px] absolute w-full bottom-full pointer-events-none',
            dotClassName
          )}
        >
          {scrollSnapList?.map((_, index) => (
            <DotButton
              key={index}
              dotStyle={dotStyle}
              selected={index === selectedScrollSnap}
              onClick={() => {
                scrollTo(index)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Carousel
