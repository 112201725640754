import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { PlusIcon } from '@heroicons/react/solid'
import { CollectionAndItemsDataFragment } from '@carrotcart/data/generated'
import NotificationCollection from '@carrotcart/app/components/atc-button/NotificationCollection'
import FilterCollections from './FilterCollections'

interface NotificationAddToCollectionProps {
  cartItemId: string
  collections?: CollectionAndItemsDataFragment[]
  fetchMoreCollections: () => void
  showNewCollection?: boolean
  filterCollections?: React.Dispatch<React.SetStateAction<string>>
  loadingCollections: boolean
  searchQuery?: string
}

const NotificationAddToCollection: React.FC<NotificationAddToCollectionProps> =
  ({
    cartItemId,
    collections,
    fetchMoreCollections,
    filterCollections,
    loadingCollections,
    searchQuery,
  }) => {
    const [selectedCollectionId, setSelectedCollectionId] = useState<string>('')
    const [newCollectionName, setNewCollectionName] = useState('')
    const shouldAutoFocus = !!cartItemId

    const [scrollProgress, setScrollProgress] = useState(0)
    const submitButton = useRef<HTMLButtonElement>(null)

    useEffect(() => {
      if (!selectedCollectionId) return
      submitButton.current?.click()
    }, [selectedCollectionId])

    useEffect(() => {
      setSelectedCollectionId('')
      setNewCollectionName('')
    }, [cartItemId])

    useEffect(() => {
      if (!scrollProgress) return
      if (loadingCollections) return
      if (scrollProgress >= 0.5) {
        fetchMoreCollections()
      }
    }, [fetchMoreCollections, scrollProgress, loadingCollections])

    const totalCollections = collections?.length || 0

    const [currentState, setCurrentState] =
      useState<'collections' | 'create'>('collections')

    const showCreateCollection =
      currentState === 'create' ||
      (!collections?.length && !loadingCollections && !searchQuery)

    const showCollectionList =
      currentState === 'collections' &&
      !showCreateCollection &&
      (!!collections?.length || searchQuery || loadingCollections)

    return (
      <>
        <button ref={submitButton} type="submit" className="hidden" />
        <input
          type="hidden"
          name="selectedCollectionId"
          value={selectedCollectionId}
        />

        <div className={clsx('justify-center flex-col')}>
          <>
            {showCollectionList && (
              <div className="flex items-center justify-center flex-col w-[216px]">
                <FilterCollections
                  totalCollections={collections?.length || 0}
                  filterCollections={filterCollections}
                  loadingCollections={loadingCollections}
                  searchQuery={searchQuery}
                />

                <div
                  onScroll={(e) => {
                    const target = e.target as HTMLDivElement
                    const scrollProgress =
                      target.scrollTop /
                      (target.scrollHeight - target.clientHeight)
                    setScrollProgress(scrollProgress)
                  }}
                  className={clsx(
                    'w-full overflow-y-auto overflow-x-hidden scr-ollbar-hide custom-scrollbar-on-page',
                    {
                      'max-h-[220px] m-ax-w-[210px]': totalCollections > 4,
                      hidden: !collections?.length,
                    }
                  )}
                >
                  {collections?.map((collection) => (
                    <NotificationCollection
                      key={collection.id}
                      collection={collection}
                      selected={collection.id === selectedCollectionId}
                      setSelectedCollectionId={setSelectedCollectionId}
                    />
                  ))}
                </div>

                <div
                  onClick={() => setCurrentState('create')}
                  className={clsx(
                    'flex cursor-pointer flex-row items-center justify-center bg-onpagelight transition-colors text-purple hover:bg-[#DFE0F9] text-sm w-full py-3 rounded-full mt-4',
                    {
                      hidden: loadingCollections,
                    }
                  )}
                >
                  <PlusIcon className="mr-1 w-5 h-5" />
                  New Collection
                </div>
              </div>
            )}
          </>

          {showCreateCollection && (
            <>
              <div className="flex flex-1 flex-col items-center max-w-[200px] w-full mx-auto">
                <label className="w-full text-[15px] font-bold text-black mb-2 text-left">
                  Collection Name
                </label>
                <input
                  onChange={(e) => {
                    setNewCollectionName(e.target.value)
                  }}
                  autoFocus={shouldAutoFocus}
                  name="createCollectionName"
                  value={newCollectionName}
                  placeholder="Wishlist"
                  className="flex w-full text-sm text-onpagegray outline-none border-[#E2E2E2] border px-2 py-3 rounded"
                />

                <button
                  type="submit"
                  className="bg-onpagelight text-purple hover:bg-[#DFE0F9] transition-colors text-sm w-full py-3 rounded-full mt-4"
                >
                  Save to Collection
                </button>

                {!!totalCollections && (
                  <button
                    className="text-[#bbbbbb] font-medium text-sm mt-2 py-2"
                    onClick={() => {
                      setCurrentState('collections')
                    }}
                  >
                    Go Back
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </>
    )
  }

export default NotificationAddToCollection
