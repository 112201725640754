import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import logger from '@carrotcart/client-common/lib/logger/web'
import { ValidateUsernameResponse } from '@carrotcart/common/types'
import { VALIDATE_USERNAME_API_ENDPOINT } from '@carrotcart/common/lib/constants'
import { getUserToken } from '@carrotcart/client-common/lib/firebase'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'

const useClaimUsername = (): [
  (usernameInput: string) => Promise<{
    respData?: ValidateUsernameResponse
    errorMessage?: string
  }>,
  {
    invalid: boolean
    hasClaimedUsername: boolean
  }
] => {
  const { authUser, currentUser } = useWebAppContext()
  const [token, setToken] = useState<string>()
  const [validationResp, setValidationResp] =
    useState<ValidateUsernameResponse | undefined>()

  const invalid = !!(
    validationResp &&
    (validationResp.valid === false || validationResp.taken)
  )

  const hasClaimedUsername = !!(
    currentUser && currentUser.has_claimed_username_computed
  )

  const validateUsername = useCallback(
    async (
      usernameInput: string
    ): Promise<{
      respData?: ValidateUsernameResponse
      errorMessage?: string
    }> => {
      try {
        const { data } = await axios.get<ValidateUsernameResponse>(
          `${VALIDATE_USERNAME_API_ENDPOINT}/${encodeURIComponent(
            usernameInput
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        const {
          valid,
          taken,
          has_alphanumeric_characters,
          has_only_authorized_characters,
          greater_than_min_length,
          less_than_max_length,
        } = data

        setValidationResp(data)

        let errorMessage: string | undefined = undefined

        if (currentUser?.username !== usernameInput && taken) {
          errorMessage = 'Username is taken'
        }

        if (!valid) {
          if (!has_alphanumeric_characters) {
            errorMessage =
              'At least one alphanumeric character is required (a-z, A-Z, 0-9)'
          } else if (!has_only_authorized_characters) {
            errorMessage =
              'Only alphanumeric, periods, underscores and dashes are allowed'
          } else if (!greater_than_min_length || !less_than_max_length) {
            errorMessage = 'Username must be between 3 to 16 characters long'
          } else {
            errorMessage = 'Invalid username'
          }
        }

        return {
          respData: data,
          errorMessage,
        }
      } catch (err) {
        logger.error({
          message:
            'There was an error validating a username, in useClaimUsername hook',
          'context.user.id': currentUser?.id,
          err,
        })

        return {
          errorMessage: 'There was an error validating the username',
        }
      }
    },
    [currentUser?.id, currentUser?.username, token]
  )

  useEffect(() => {
    if (!authUser) return
    ;(async () => {
      const tokenResult = await getUserToken(authUser)
      setToken(tokenResult?.token)
    })()
  }, [authUser])

  return [validateUsername, { invalid, hasClaimedUsername }]
}

export default useClaimUsername
