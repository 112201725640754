import type * as Types from '../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type FindShopItemAndShopByUrlQueryVariables = Types.Exact<{
  url: Types.Scalars['String']
  shopSlug: Types.Scalars['name']
}>

export type FindShopItemAndShopByUrlQuery = {
  __typename?: 'query_root'
  shop_item: Array<{
    __typename?: 'shop_item'
    id: any
    item_id: string
    url?: Types.Maybe<string>
    title?: Types.Maybe<string>
    image?: Types.Maybe<string>
    display_title?: Types.Maybe<string>
    source?: Types.Maybe<string>
    enrichment_status?: Types.Maybe<Types.Shop_Item_Enrichment_Status_Enum>
    shop: {
      __typename?: 'shop'
      id: any
      key: string
      type?: Types.Maybe<any>
      name: any
      slug: any
      favicon?: Types.Maybe<string>
    }
  }>
  shop: Array<{
    __typename?: 'shop'
    id: any
    key: string
    type?: Types.Maybe<any>
    name: any
    slug: any
    favicon?: Types.Maybe<string>
  }>
}

export const FindShopItemAndShopByUrlDocument = gql`
  query FindShopItemAndShopByUrl($url: String!, $shopSlug: name!) {
    shop_item(where: { url: { _eq: $url } }) {
      id
      item_id
      url
      title
      image
      display_title
      source
      enrichment_status
      shop {
        id
        key
        type
        name
        slug
        favicon
      }
    }
    shop(
      where: { slug: { _eq: $shopSlug } }
      order_by: { cart_items_count: desc }
      limit: 1
    ) {
      id
      key
      type
      name
      slug
      favicon
    }
  }
`

/**
 * __useFindShopItemAndShopByUrlQuery__
 *
 * To run a query within a React component, call `useFindShopItemAndShopByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindShopItemAndShopByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindShopItemAndShopByUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *      shopSlug: // value for 'shopSlug'
 *   },
 * });
 */
export function useFindShopItemAndShopByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >(FindShopItemAndShopByUrlDocument, options)
}
export function useFindShopItemAndShopByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindShopItemAndShopByUrlQuery,
    FindShopItemAndShopByUrlQueryVariables
  >(FindShopItemAndShopByUrlDocument, options)
}
export type FindShopItemAndShopByUrlQueryHookResult = ReturnType<
  typeof useFindShopItemAndShopByUrlQuery
>
export type FindShopItemAndShopByUrlLazyQueryHookResult = ReturnType<
  typeof useFindShopItemAndShopByUrlLazyQuery
>
export type FindShopItemAndShopByUrlQueryResult = Apollo.QueryResult<
  FindShopItemAndShopByUrlQuery,
  FindShopItemAndShopByUrlQueryVariables
>
