import React, { InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
//
import Loader from '@carrotcart/client-common/assets/images/loader.svg'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  small?: boolean
  invalid?: boolean
  hasValue?: boolean
  processing?: boolean
  error?: string | undefined
}

const UsernamePicker = React.forwardRef<HTMLInputElement, Props>(
  ({ small, invalid, hasValue, processing, error, ...rest }, ref) => {
    // const [placeholder] = useState(getRandomUsername())

    return (
      <div className="space-y-1">
        <div
          className={clsx(
            'form-input align-center flex items-center relative font-semibold rounded-full',
            {
              error,
            }
          )}
        >
          <div
            className={clsx(
              'text-gray-400 font-semibold pointer-events-none leading-none',
              {
                'min-w-[20px] text-[24px]': small,
                'min-w-[30px] text-[34px]': !small,
              }
            )}
          >
            @
          </div>
          <input
            ref={ref}
            type="text"
            disabled={processing}
            placeholder="username"
            className={clsx('relative outline-none px-1 w-full', {
              'h-[40px] text-3xl md:h-[60px] md:text-4xl bottom-[2px]': !small,
              'px-[2px]': small,
            })}
            {...rest}
          />

          <div
            className={clsx('pointer-events-none', {
              'min-w-[25px]': small,
              'min-w-[30px]': !small,
            })}
          >
            {!processing && invalid && (
              <ExclamationCircleIcon className="w-7 h-7 text-red" />
            )}

            {!processing && hasValue && !invalid && (
              <CheckCircleIcon className="w-7 h-7 text-green" />
            )}

            {processing && (
              <div
                className={clsx(
                  'pointer-events-none bg-white transition ease-in-out duration-200'
                )}
              >
                <div
                  className={clsx('animate-spin text-black', {
                    'w-7 h-7': !small,
                    'w-6 h-6': small,
                  })}
                >
                  <Loader />
                </div>
              </div>
            )}
          </div>
        </div>
        {error && (
          <div className={clsx('text-red-500 text-sm transition-opacity')}>
            {error || <br />}
          </div>
        )}
      </div>
    )
  }
)

UsernamePicker.displayName = 'UsernamePicker'

export default UsernamePicker
