import React from 'react'
import {
  AddNewCollectionMutation,
  GetUserCreatedCollectionsQuery,
} from '@carrotcart/data/generated'
import { Collection_State_Enum } from '@carrotcart/data/generated/types'
import { getCoverImageFromCollection } from '@carrotcart/client-common/lib/helpers'
import PictureAvatarGrouping from '@carrotcart/app/components/PictureAvatarGrouping'
import PremiumIcon from '@carrotcart/client-common/assets/images/extension/premium-icon.svg'
import PrivateIcon from '@carrotcart/client-common/assets/images/extension/private-icon.svg'
import CollectionHover from '@carrotcart/client-common/assets/images/extension/collection-hover.svg'
import CollectionTick from '@carrotcart/client-common/assets/images/extension/selected-collection-tick.svg'

interface NotificationCollectionProps {
  collection?:
    | AddNewCollectionMutation['collection']
    | GetUserCreatedCollectionsQuery['collections'][0]
  selected: boolean
  setSelectedCollectionId: React.Dispatch<React.SetStateAction<string>>
}

const isCollectionWithItems = (
  object: any
): object is GetUserCreatedCollectionsQuery['collections'][0] => {
  return 'collection_items' in object
}

const NotificationCollection: React.FC<NotificationCollectionProps> = ({
  collection,
  selected,
  setSelectedCollectionId,
}) => {
  const type = collection?.private
    ? 'private'
    : collection?.state === Collection_State_Enum.Draft
    ? 'draft'
    : collection?.paid //|| collection?.exclusive
    ? 'premium'
    : 'public'

  const avatars = [collection?.user?.picture]
  const collaborators = collection?.collection_collaborators.map(
    (collaborator) => collaborator?.user?.picture
  )
  avatars.push(...collaborators)
  const collaborative = collection?.has_collaborators
  const name = collection?.name
  const image = isCollectionWithItems(collection)
    ? getCoverImageFromCollection(collection)
    : null

  return (
    <div
      onClick={() => {
        if (selected) {
          setSelectedCollectionId('')
        } else {
          setSelectedCollectionId(collection?.id)
        }
      }}
      className="relative w-full flex space-x-1 py-1.5 flex-row justify-between items-center pl-1.5 cursor-pointer hover:bg-gray-50 group"
    >
      <div className="flex flex-row items-center">
        <div className="items-center justify-center">
          {image ? (
            <img
              className="w-8 h-8 min-w-[32px] rounded bg-gray-50 bg-blend-multiply object-cover"
              src={image}
            />
          ) : (
            <div className="w-8 h-8 min-w-[32px] rounded bg-gray-50" />
          )}
        </div>
        <div className="flex flex-row items-center">
          {type === 'premium' ? (
            <PremiumIcon className="min-w-[14px] ml-1.5" />
          ) : (
            type === 'private' && (
              <PrivateIcon className="min-w-[14px] ml-1.5" />
            )
          )}
          <div className="ml-1.5 text-xs text-onpagegray line-clamp-2 leading-[14px]">
            {name}
          </div>
        </div>
      </div>
      {collaborative && (
        <div className="flex flex-row items-center transition-opacity group-hover:opacity-0 opacity-100">
          <PictureAvatarGrouping
            limit={3}
            pictures={avatars}
            picturesCount={avatars?.length || 0}
            rounded
            size="xx-small"
            bgColor="bg-white"
          />
        </div>
      )}
      {selected ? (
        <div className="absolute transition-opacity right-1">
          <CollectionTick className="text-[#3EB777] w-6" />
        </div>
      ) : (
        <div className="absolute transition-opacity group-hover:opacity-100 opacity-0 right-1">
          <CollectionHover className="w-6" />
        </div>
      )}
    </div>
  )
}

export default NotificationCollection
