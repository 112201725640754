import { memo, SVGProps } from 'react'

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 21}
    height="100%"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.755 15.158v-4.443c-5.716 0-9.5 1.726-12.359 5.535 1.159-5.434 4.352-10.842 12.36-11.934V0l7.235 6.652c.54.508.54 1.346 0 1.854l-7.236 6.652Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(ShareIcon)
export default Memo
